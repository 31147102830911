.profile-img-col{
    display: flex;
    flex-direction: column;
    border-radius:10px;
    padding-top: 35px;
    overflow: hidden;
    position: relative;
    align-items: center;
}

.profile-img{
    border-radius: 50%;
    /* padding: 0; */
    border:#73a0e9 solid 3px;
    height: 200px !important; 
    width: 200px !important;
    object-fit: cover;
}
.detail-col{
    /* min-height:350px;  */
    border-radius:10px;
}