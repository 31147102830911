.font-bold{
    font-size:14px;
    font-weight:600;
}
.font-semibold{
    font-weight: 500;
}
.line-height-28{
    line-height:1.3 !important; 
    /* background-color: #eef2ff; */
}
.ant-input-number{
    height: 26px;
    align-items: center;
    display: flex;
    border-radius: 0px 6px 6px 0px !important;
}
.ant-input[disabled]{
    background-color: #eef2ff;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background-color: #eef2ff;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    line-height: 28px !important;
    padding: 0px 0px 0px 11px !important;
}
.are-you-sure{
    padding: 5px 10px;
    background-color: #eef2ff;
    border-radius: 5px;
}
.ant-input-number-affix-wrapper{
    color: rgba(0, 0, 0, 0.25);
    background: #eef2ff!important;
}
.ant-input-number-prefix{
    color: rgba(0, 0, 0, 0.25);
    background: #eef2ff!important;
}
.ant-input-number-disabled{
    color: rgba(0, 0, 0, 0.25);
    background: #eef2ff!important;
    cursor: not-allowed;
}
.ant-select-disabled .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #eef2ff!important;
    cursor: not-allowed;
}
.ant-picker-disabled .ant-picker-input  {
    color: rgba(0, 0, 0, 0.25);
    background: #eef2ff!important;
    cursor: not-allowed;
}
.ant-input-number-affix-wrapper .ant-input-number-prefix {
    padding-right: 5px;
    color: #5e5e5e !important;
}
.bg-secondary{
    background-color: #ffffff !important;
}
.edit-btn:hover{
    background-color: #e0e0e0;
    
}
.edit-btn{
    cursor: pointer;
    padding:0px 5px ; 
    font-size: 16px !important;
    border-radius: 5px;
    width: fit-content;
}
.delete-btn:hover{
    background-color: #ffe6e0;
    
}
.delete-btn{
    cursor: pointer;
    padding:2px 5px ; 
    font-size: 16px !important;
    border-radius: 5px;
    width: fit-content;
}
.mail-radio-btn{
    flex-direction: column;
    align-items: center;
    height:fit-content;
    padding:5px;
    width: 70px;
    height: 81px;
}
.mail-radio-btn-span{
    display:flex;
    flex-direction:column; 
    align-items:center; 
    padding:5px 0px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0093FF  !important; 
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
    color: #0093FF  !important; 
}
.ant-btn-primary{
    color: #fff;
    /* background-image: linear-gradient(to right, #0098FF , #0066FE); */
    background-image: linear-gradient(to right,#1599f1, #6cc4ff  );
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:hover{
    color: #000;
    /* background-image: linear-gradient(to right, #6cc4ff , #1599f1); */
    transition: 2seconds;
    /* background-image: linear-gradient(to right, #0070bb , #004fc5); */
    background-image: linear-gradient(to right, #007fd4,#41b1fc  );
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-tabs .ant-tabs-tab:hover {
    color: #0093FF ;
}
.ant-tabs-ink-bar {
     border-bottom: #0093FF  2px solid;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
}
.ant-checkbox-disabled .ant-checkbox-inner {
    background: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1d9bf0;
    border-color: #1d9bf0;
    z-index: 2;
}
.custom-form-item .ant-form-item-label{
     padding: 0 !important;
}
.custom-form-item{
    margin-bottom: 4px !important;
}
.ant-breadcrumb-link{
    color: #000;
    cursor: pointer;
}
.ant-breadcrumb-link:hover{
    color: #1d9bf0;
    cursor: pointer;
}
.ck.ck-editor__main>.ck-editor__editable{
    min-height: 200px;
}
