/*----- css login page------*/

input:-webkit-autofill {
    -webkit-background-clip: text;
}

.loginnames {
    color: #214E9B;
    height: 16px;
    line-height: 0.8;
}

.lgname {
    color: #707070;
}

/*----- responsive css login page------*/

@media (max-width:450px) {

    .ant-checkbox + span {
        padding-inline-end: 0px;
    }
}

/*-----ant login form custom css ------*/


.loginInput {
    border: none;
    outline: none;
    background-color: #F9FAFF;
    Border-Radius: 0px !important;
}

    .loginInput input {
        border: none;
        outline: none;
        background-color: #F9FAFF;
        Border-Radius: 0px !important;
    }

    .loginInput:focus {
        Border-Radius: 0px !important;
        box-shadow: none !important;
    }
