
/*-----------------Navbar css---------------------- */
.linkcolor {
    color: #0d6efd;
}

.logo-min {
    display: none;
}

.ant-layout-has-sider {
    flex-direction: unset !important;
}

.ant-layout {
    background-color: #eef2ff;
}
.ant-layout .ant-layout-header {
    height: 44px;
    /* padding-inline: 50px; */
    color: rgba(0, 0, 0, 0.88);
    /* line-height: 64px; */
    background: #001529;
}


.ant-layout-sider {
    max-width: 255px !important;
    min-width: 255px !important;
    box-shadow: 2px 1px 5px rgb(81 100 120 / 8%);
    z-index: 99;
    transition: all 0.4s !important;
}

.ant-layout-sider-collapsed {
    max-width: 60px !important;
    min-width: 60px !important;
    overflow-x: hidden !important;
}

.ant-menu-title-content {
    margin-left: 16px !important;
}

.ant-menu-inline-collapsed {
    width: 61px !important;
}
    .ant-menu-inline-collapsed .ant-menu-item {
        padding-inline:  calc(50% - 15px) !important;
    }

    .ant-menu-vertical {
        border-inline-end: 0px solid rgba(5, 5, 5, 0.06) !important;
    }

.sidebar .ant-menu-item {
    margin: 0px !important;
    border-radius: 0 !important;
    border-left: 3px solid white !important;
    margin:0px 0px 10px 0px !important;
}

    .ant-menu-item:hover {
        border-left: 3px solid white !important; 
        background-color: white !important; 
    }

.sidebar .ant-menu-item-selected {
    background: #eef2ff !important;
    border-left: 3px solid #1d9bf0 !important;
    color: #1d9bf0 !important;
    font-weight: 600;
}
.sidebar  .ant-menu-item {
    width: 100% !important;
}


.ant-menu-item-selected:hover {
    border-left: 3px solid #1d9bf0 !important;
    background-color: #eef2ff;
}

.ant-menu-inline {
    border-inline-end: none !important;
    margin-top: 24px!important;
}
.ant-menu-vertical {
    margin-top: 24px !important;
}

.sidebar .anticon {
    color: #4285f4 !important;
    font-size: 20px !important;
    height: 100% !important;
} 

.aoa-layout {
    padding: 6px 20px !important;
    background-color: #1d9bf0 !important;
    display: flex !important;
    justify-content: space-between !important;
    position: sticky !important;
    top: 0 !important;
    z-index:1!important;
    width: 100% !important;
}

.searchIcon {
    visibility: hidden;
}

.searchBar {
    position: absolute;
    top: 65px;
    width: 94%;
    padding: 15px;
    background-color: white;
    margin-left: 3%;
    border-radius: 8px;
}
.appheading{
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
}
/*-----------------Employment Form css---------------------- */
.mainRow{
    padding: 16px 70px!important;
}
/*=========== Employment Form Input Suffix ============ */

.monthSuffix .ant-input-suffix {
    font-size: 12px!important;
}

@media(max-width:1200px) {
    .logo-min {
        display: block !important;
        padding-top: 15px;
        width: 48px;
        height: 64px;
        box-shadow: 0 0 3px rgb(0 0 0 / 9%);
        clip-path: inset(0px -15px 0px 0px);
    }

    .aoa-layout {
        padding: 6px 20px 6px 10px !important;
    }

    .headerSearch {
        display: none;
    }

    .searchIcon {
        visibility: visible;
    }

    .layout-image {
        margin-left: 20px !important;
    }

    .ant-layout {
        width: 100%;
        margin-left: 0px !important;
    }

    /*-----------------Employment Form css---------------------- */
    .mainRow {
        padding: 16px !important;
    }
}


/*-----------------Navbar css---------------------- */
